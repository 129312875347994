/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import "prismjs/themes/prism-tomorrow.css";
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import CodeBlock from './src/components/code-block';

require("./src/styles/global.css")

const components = {
  code: CodeBlock,
};

export const wrapRootElement = ({ element }) => {
  return (
    <MDXProvider components={components}>
      {element}
    </MDXProvider>
  );
};

